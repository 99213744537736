import { h, toChildArray } from 'preact'
import { useMemo } from 'preact/hooks'
import classNames from 'clsx'
import Prism from 'prismjs'

import "prismjs/themes/prism.css"

import styles from './codeblock.module.scss'

function Codeblock(props) {
  const { children, class: _class, className, language, ...rest } = props

  const content = useMemo(() => {
    if (!language || !(language in Prism.languages)) {
      return
    }

    const content = toChildArray(children).map(el => el.toString()).join('\n')

    return Prism.highlight(content, Prism.languages[language], language)
  }, [language, children])

  return (
    <pre className={classNames(className, _class, styles['a-codeblock'])}>
      <code
        {...rest}
        className={classNames(language && `language-${language}`)}
        dangerouslySetInnerHTML={content && { __html: content }}
      >
        {children}
      </code>
    </pre>
  )
}

export default Codeblock
